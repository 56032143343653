export const menuItems = [
  {
    name: 'products',
    label: 'Products',
    items: [
      { name: 'https://pointbee.app', label: 'Pointbee', isExternal: true },
      { name: 'qpon', label: 'Qpon' },
    ],
  },
  {
    name: 'company',
    label: 'Company',
    items: [
      { name: 'about', label: 'About Us' },
      { name: 'contact', label: 'Contact Us' },
    ],
  },
]
