import React from 'react'
import { Link } from 'gatsby'

import LogoBlack from '../../assets/image/logo/logo-with-text-black.svg'
import LogoWhite from '../../assets/image/logo/logo-with-text-white.svg'

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <LogoWhite alt="dot dot data" />
      ) : (
        <LogoBlack alt="dot dot data" />
      )}
    </Link>
  )
}

export default Logo
