import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import GlobalContext from '../../context/GlobalContext'
import Logo from '../Logo'

const Footer = () => {
  const gContext = useContext(GlobalContext)

  const linkClassName =
    gContext.footer.theme === 'dark'
      ? 'gr-text-color gr-hover-text-green'
      : 'gr-text-color gr-hover-text-blue'

  const iconClassName =
    gContext.footer.theme === 'dark'
      ? 'text-storm gr-hover-text-green'
      : 'gr-text-color gr-hover-text-blue'

  return (
    <>
      <div
        className={`footer-section pt-15 pt-lg-25 pb-lg-21 ${
          gContext.footer.theme === 'dark'
            ? 'dark-mode-texts bg-blackish-blue'
            : ''
        }`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="3" md="4">
              <Logo
                white={gContext.footer.theme === 'dark'}
                className="footer-logo mb-11"
                style={{ width: 175 }}
              />
              <p className="gr-text-11">
                We help businesses step into their vision through modern
                software.
              </p>
              <ul className="social-icons py-7 list-unstyled mb-7 mb-lg-0">
                <li className="mr-2">
                  <Link
                    to="https://twitter.com/dotdotdata"
                    className={iconClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-logo-twitter"></i>
                  </Link>
                </li>
                <li className="mr-2">
                  <Link
                    to="https://www.facebook.com/dotdotdata"
                    className={iconClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-logo-facebook"></i>
                  </Link>
                </li>
                <li className="mr-2">
                  <Link
                    to="https://www.instagram.com/dotdotdata"
                    className={iconClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-instant-camera-2"></i>
                  </Link>
                </li>
                <li className="mr-2">
                  <Link
                    to="https://www.linkedin.com/company/dotdotdata"
                    className={iconClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon icon-logo-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg="8" md="8" className="offset-lg-1">
              <Row>
                <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Company</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/about" className={linkClassName}>
                          About us
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/contact" className={linkClassName}>
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Products</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <a
                          href="https://pointbee.app"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={linkClassName}
                        >
                          Pointbee
                        </a>
                      </li>
                      <li className="py-2">
                        <Link to="/qpon" className={linkClassName}>
                          Qpon
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Legal</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/attribution" className={linkClassName}>
                          Attribution
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer
