// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-attribution-js": () => import("./../../../src/pages/attribution.js" /* webpackChunkName: "component---src-pages-attribution-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qpon-js": () => import("./../../../src/pages/qpon.js" /* webpackChunkName: "component---src-pages-qpon-js" */)
}

